import { createStore } from 'owa-satcheljs';
import { checkGlobalSettingsReady } from '../utils/checkGlobalSettingsReady';
import {
    getSessionCount,
    getandIncreaseSessionCount,
    getNpsDismissCount,
} from '../utils/localStorageHelper';
import type { OwaSessionStore } from './schema/OwaSessionStore';
import { isRunningOnWorker } from 'owa-config';

function getInitialStore(): OwaSessionStore {
    return {
        nonGlobalAccountDataMap: new Map([]),
        userConfiguration: undefined,
        userSessionCount: isRunningOnWorker() ? getSessionCount() : getandIncreaseSessionCount(),
        npsDismissCount: getNpsDismissCount(),

        defaultFolderIdToNameMap: new Map([]),
        sharedMailboxFolderInformationMaps: new Map([]),
        sharedMailboxFolderIdToNameMap: new Map([]),
        connectedAccountsUserConfigurationMap: new Map([]),

        allowDefaultDataReturn: false,
    };
}

let store: OwaSessionStore;

export const getStore: () => OwaSessionStore = () => {
    checkGlobalSettingsReady();
    return store;
};

export const getRawStoreInternalOnly: () => OwaSessionStore = () => {
    if (!store) {
        initializeStore();
    }

    return store;
};

function initializeStore() {
    store = createStore<OwaSessionStore>('owaSessionStore', getInitialStore())();
}
