/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { getItem, setItem, type LocalStorageKeys } from 'owa-local-storage';

const LocalStorageKeys: Record<string, LocalStorageKeys> = {
    OwaSessionCount: 'OwaSessionCount',
    NpsDismissCount: 'NpsDismissCount',
};

/**
 * Internal usage
 * Owa session count
 */
export function getandIncreaseSessionCount(): number {
    const sessionCount = getSessionCount();

    setItem(self, LocalStorageKeys.OwaSessionCount, (sessionCount + 1).toString());

    return sessionCount;
}

export function getSessionCount(): number {
    return parseInt(getItem(self, LocalStorageKeys.OwaSessionCount) ?? '0');
}

/**
 * Internal usage
 * Nps Dismiss count
 */
export function getNpsDismissCount(): number {
    return parseInt(getItem(self, LocalStorageKeys.NpsDismissCount) ?? '0');
}

export function incrementNpsDismissCount(): void {
    const npsDismissCount = getNpsDismissCount();

    setItem(self, LocalStorageKeys.NpsDismissCount, (npsDismissCount + 1).toString());
}
