import { isBootFeatureEnabled } from 'owa-metatags';
import { getConfig } from 'owa-service/lib/config';

/**
 * Returns whether the shared coprincipal account functionality is enabled. Which requires both
 * the feature flag (acct-sharedcpa) and the boot flight for persisnce ids to be enabled.
 */
export function isSharedCoprincipalAccountEnabled(): boolean {
    return (
        getConfig().isFeatureEnabled('acct-sharedcpa', undefined) &&
        isBootFeatureEnabled('acctPersistentIdIndexerV2')
    );
}
