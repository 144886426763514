import { default as getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import type { MailboxInfo } from 'owa-client-types';
import {
    getAccountKeyForMailboxInfo,
    isSameCoprincipalAccountMailboxInfos,
} from 'owa-client-types';
import { getStore } from '../store/store';

export function getAccountSettingsInternal(mailboxInfo: MailboxInfo) {
    const store = getStore();
    if (
        store.allowDefaultDataReturn &&
        store.userConfiguration === undefined &&
        store.nonGlobalAccountDataMap.size === 0
    ) {
        // there is not any configuration data set in the store and we allow default data
        // so we just return undefined here rather than checking the global settings account
        return undefined;
    }

    // Can use IsKeySameAccountAsGlobalSettingsAccount after updating testing
    const settings = isSameCoprincipalAccountMailboxInfos(
        getGlobalSettingsAccountMailboxInfo(),
        mailboxInfo
    )
        ? store.userConfiguration
        : store.nonGlobalAccountDataMap.get(getAccountKeyForMailboxInfo(mailboxInfo));
    return settings;
}
