import { getCoprincipalAccountForIndexerValue } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

export function isMailboxSharedOrDelegateUserMailbox(mailboxInfo: MailboxInfo): boolean {
    if (mailboxInfo.type === 'UserMailbox') {
        // First check the MailboxInfo to see if it is marked as an explicit logon
        if (mailboxInfo.isExplicitLogon) {
            return true;
        }

        // Second check the account to see if it is marked as an additional mailbox
        const account = getCoprincipalAccountForIndexerValue(
            getIndexerValueForMailboxInfo(mailboxInfo)
        );
        if (account?.isAnotherMailbox) {
            return true;
        }

        // Finally check the session setting to see if it is marked as an explicit logon. We check
        // the SessionSettings because it is present in both OWA and Monarch
        // TODO: Need to move this function into the owa-account-shared-mailbox-utils package then
        // we can call the commonly used function isAccountExplicitLogon, which we cannot
        // do now becuase it would create a circular package dependency.
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * IsExplicitLogon must be checked for a specific account. Use isAccountExplicitLogon or get approval for the usage.
         *	> 'IsExplicitLogon' is restricted from being used. IsExplicitLogon checks must be account specific, use isAccountExplicitLogon or get approval if that is not possible. */
        return !!getAccountScopeUserSettings(mailboxInfo).SessionSettings?.IsExplicitLogon;
    }

    return false;
}
