import type { AccountSource } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

/**
 * Returns true if the account source is a PST file account
 * @param account Account to check
 * @returns True if it is a PST file account, false otherwise
 */
export default function isPstFile(account: AccountSource): boolean {
    return isPstFileAccountType(account.sourceType);
}

/**
 * Returns true if the account source type is for a PST file account
 * @param account Account type to check
 * @returns True if it is a PST file account type, false otherwise
 */
export function isPstFileAccountType(accountType: AccountSourceType): boolean {
    return accountType === AccountSourceType.PstFile;
}
