import { getHostLocation } from 'owa-url/lib/hostLocation';
import { isBootFeatureEnabled } from 'owa-metatags';

export function getAnchorMailboxHeaderForExplicitLogon() {
    let header = '';

    // see if this is an explicit logon scenario, where the target mailbox is specified in the path
    // e.g., /mail/user@contoso.com/inbox/ and /mail/user%40contoso.com/inbox/
    const path = getHostLocation().pathname;

    const segments = path.split('/').filter(part => part.length);
    if (segments.length >= 2) {
        const testSegmentPartOne = isBootFeatureEnabled('fwk-decode-checkexplictlogon')
            ? decodeURIComponent(segments[1])
            : segments[1];
        if (testSegmentPartOne.match(/.+@.+/)) {
            header = decodeURIComponent(segments[1]);
        }
    }

    return header;
}
